import { mapGetters } from "vuex";
import { debounce, throttle } from "lodash";
import { decodeHtml } from "../../../router/utils";

export default {
  name: "SearchBar",
  data() {
    return {
      isSmallScreen: window.innerWidth <= 600,
    };
  },
  methods: {
    onInput: debounce(
      throttle(function (event) {
        this.error = null;
        this.$emit("termChange", event.target.value);
      }, 250),
      500
    ),
    handleResize() {
      this.isSmallScreen = window.innerWidth <= 600; 
    },
    decodeHtml,
  },
  computed: {
    ...mapGetters("languages", ["languages", "activeLanguage"]),
    ...mapGetters("staticContent", ["getTranslations"]),
    ...mapGetters("processes", ["currentCategory", "currentSector"]),
    placeholder() {
      if (this.isSmallScreen && this.activeLanguage.two_digit_code === "en") {
        return "Search " + this.currentSector?.title;
      } else if(this.activeLanguage.two_digit_code === "en") {
        return (
          "Search " +
          this.currentSector?.title +
          " for solutions, processes & equipment"
        );
      }
      
      if(this.activeLanguage.two_digit_code != "en"){
        return this.getTranslations("search for solutions, processes, and equipment");
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
