<template>
  <button
    @click="openInnovations"
    type="button"
    class="innovation-btn shadow-blue-500/50 text-semibold flex hidden items-center gap-2.5 rounded-[28px] bg-[#ED8C1A] px-3 py-1.5 text-center text-sm text-white shadow-lg lg:flex capitalize"
  >
    <svg
      width="29"
      height="38"
      viewBox="0 0 29 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
    >
      <path
        d="M14.1313 37.8812C13.3 37.8812 12.5974 37.624 12.0234 37.1094C11.4495 36.5948 11.1625 35.9417 11.1625 35.15H9.38125C8.94583 35.15 8.5599 34.9818 8.22344 34.6453C7.88698 34.3089 7.71875 33.9229 7.71875 33.4875V26.7187C5.225 25.4125 3.3151 23.6115 1.98906 21.3156C0.663021 19.0198 0 16.625 0 14.1312C0 10.1729 1.36563 6.82812 4.09688 4.09687C6.82813 1.36562 10.1729 0 14.1313 0C18.0896 0 21.4344 1.36562 24.1656 4.09687C26.8969 6.82812 28.2625 10.1729 28.2625 14.1312C28.2625 16.625 27.5995 19.0198 26.2734 21.3156C24.9474 23.6115 23.0375 25.4125 20.5438 26.7187V33.4875C20.5438 33.9229 20.3755 34.3089 20.0391 34.6453C19.7026 34.9818 19.3167 35.15 18.8813 35.15H17.1C17.1 35.9417 16.813 36.5948 16.2391 37.1094C15.6651 37.624 14.9625 37.8812 14.1313 37.8812ZM9.38125 33.4875H18.8813V31.1125H9.38125V33.4875ZM9.38125 29.45H18.8813V27.075H9.38125V29.45ZM8.90625 25.4125H13.3V17.6937L8.43125 12.825L9.61875 11.6375L14.1313 16.15L18.6438 11.6375L19.8313 12.825L14.9625 17.6937V25.4125H19.3563C21.4938 24.3833 23.2354 22.8693 24.5813 20.8703C25.9271 18.8714 26.6 16.625 26.6 14.1312C26.6 10.6479 25.3927 7.69896 22.9781 5.28437C20.5635 2.86979 17.6146 1.6625 14.1313 1.6625C10.6479 1.6625 7.69896 2.86979 5.28438 5.28437C2.86979 7.69896 1.6625 10.6479 1.6625 14.1312C1.6625 16.625 2.33542 18.8714 3.68125 20.8703C5.02708 22.8693 6.76875 24.3833 8.90625 25.4125Z"
        fill="white"
      />
    </svg>
    {{ getTranslations('innovation') }}
  </button>
</template>

<script lang="js" src="./InnovationButton.js"></script>
<style lang="scss" src="./InnovationButton.scss" scoped></style>
