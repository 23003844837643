import { mapActions, mapGetters } from "vuex";
export default {
  name: "InnovationButton",
  methods: {
    ...mapActions(["setActiveLayer"]),
    ...mapActions("portfolios", ["setActivePortfolio"]),
    ...mapActions("valueDrivers", ["setActiveValueDrivers"]),
    openInnovations(){
      this.setLayerAndRedirect({id: "solutions"});
      let innovations ={id: 7, slug: 'innovation', name: 'Innovation'}
      this.setActivePortfolio({ activePortfolio: innovations });
    },
    setLayerAndRedirect(layer) {
      this.$router.push({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: layer.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
  },
};
