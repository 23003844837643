<template>
  <form @submit.prevent="" class="px-4 py-2">
    <div
      class="flex h-12 gap-5 text-[#0f0f98]"
    >
      <input
      id="search-input"
        class="w-full bg-transparent text-lg placeholder:text-gray-600 border-0 focus:border-0 border-transparent focus:border-transparent focus:ring-0"
        :placeholder="decodeHtml(placeholder)"
        type="text"
        @input="onInput"
      />
      <button class="flex items-center bg-[#F3F2F9] px-2 rounded-lg justify-center hover:opacity-60">
        <img width="40" src="/images/icons/icon-search-blue.png" />
      </button>
    </div>
  </form>
</template>

<script src="./SearchBar.js" lang="js"></script>