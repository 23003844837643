<template>
  <div
    class="absolute left-0 z-[100] flex overflow-hidden transition-all duration-300 top-0 rounded-[0.5rem] gallery"
    :class="{
      '-mt-[4%]': isGalleryFullScreen,
    }"
    :style="{
      width: isGalleryFullScreen ? maximizedWidth : minimizedWidth,
      height: isGalleryFullScreen ? maximizedHeight : minimizedHeight,
    }"
  >
    <div
      v-if="images.length > 1"
      class="absolute top-1/2 z-[200] flex w-full -translate-y-1/2 items-center justify-between self-center"

    >
      <button
        @click="prevSlide"
        class="bg-white bg-opacity-70 p-1 text-black shadow-lg backdrop-blur-sm backdrop-filter transition-all duration-200 hover:bg-opacity-100"
      >
        <ChevronLeftIcon class="h-[24px] w-[24px]" />
      </button>
      <button
        @click="nextSlide"
        class="bg-white bg-opacity-70 p-1 text-black shadow-lg backdrop-blur-sm backdrop-filter transition-all duration-200 hover:bg-opacity-100"
      >
        <ChevronRightIcon class="h-[24px] w-[24px]" />
      </button>
    </div>
    <div
      id="slides"
      class="absolute flex h-full w-full transition-all duration-300"
      :style="{
        transform: `translateX(-${currentSlide * 100}%)`,
      }"
    >
      <img
        v-for="img in images"
        :src="img"
        :alt="img"
        :key="img"
        class="w-full shrink-0 object-cover"
      />
    </div>
    <div
      v-if="images.length > 1"
      class="absolute bottom-0 flex w-full items-center justify-center gap-1 bg-white bg-opacity-70 py-1 backdrop-blur-sm backdrop-filter"
    >
      <div
        v-for="(img, index) in images"
        :key="img"
        @click="setActiveSlide(index)"
        class="h-2 w-2 rounded-full bg-[#0f0698] transition-all duration-200"
        :class="img === images[currentSlide] ? 'opacity-100' : 'opacity-50'"
      ></div>
    </div>
    <button
      v-if="canFullScreen"
      @click="toggleFullScreenGallery"
      class="absolute right-0 cursor-pointer bg-white bg-opacity-70 backdrop-blur-sm backdrop-filter transition-all duration-200 hover:bg-opacity-100"
      :class="images.length > 1 ? 'bottom-4' : 'bottom-0'"
    >
      <ArrowsPointingOutIcon
        v-if="!isGalleryFullScreen"
        class="h-[28px] w-[28px] transition-all duration-200 hover:text-[#0f0698]"
      />
      <ArrowsPointingInIcon
        v-else
        class="h-[28px] w-[28px] transition-all duration-200 hover:text-[#0f0698]"
      />
    </button>
  </div>
</template>

<script src="./ImageGallery.js"></script>
<style scoped src="./ImageGallery.scss" lang="scss"></style>
