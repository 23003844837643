<template>
  <teleport to="#app">
    <BaseDialog
      :isOpen="isVisible"
      @close="close"
      @runAfterLeave="runAfterLeave"
    >
      <template #default>
        <div
          class="relative flex h-[90vh] w-[95vw] flex-col gap-4 overflow-scroll px-8 pb-8 pt-4 md:h-[80vh] md:w-[80vw]"
        >
          <button
            @click="close"
            class="z-5 fixed absolute right-4 flex items-center justify-center overflow-hidden rounded-full bg-[#0f0698] p-2 md:right-12 md:top-8"
          >
            <XMarkIcon
              class="h-[22px] w-[22px] text-[#fff] transition-all duration-300 hover:text-[#0f0698]"
            />
          </button>

          <div class="flex flex-col md:flex-row md:gap-20">
            <aside class="h-full pt-20 md:pt-0">
              <img
                :src="iconSrc"
                alt=""
                class="sticky top-5 mx-auto w-[60%] md:m-0 md:h-64 md:w-full md:pt-12 md:pl-3"
              />
            </aside>

            <aside
              class="flex flex-1 flex-col items-start justify-start gap-4 pt-8 md:pt-12 md:pr-28"
            >
              <h1
                v-html="title"
                class="w-full text-left font-bold font-bold md:mb-8 md:pt-8 md:text-4xl"
              ></h1>
              <div class="value-driver leading-7" v-html="description"></div>

              <hr class="my-8 w-full border-[1px] border-[#dfe6ea]" />

              <section>
                <h4 class="mb-4 text-lg">
                  {{ getTranslations("related solutions") }}
                </h4>
                <div class="flex w-full flex-wrap items-center gap-4">
                  <RouterLink
                    v-for="(related, index) in related_solutions"
                    :key="index"
                    :to="related.link"
                    @click="close"
                  >
                    <SquareArrowButton :title="related.title" type="solution" />
                  </RouterLink>
                </div>
              </section>
            </aside>
          </div>

          <div class="valuedriver-aside"></div>
        </div>
      </template>
    </BaseDialog>
  </teleport>
</template>
<script src="./ValueDriverModal"></script>
<style scoped lang="scss" src="./Style.scss"></style>
