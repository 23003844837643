<template>
  <div
    class="flex flex-wrap w-full items-center gap-2 md:h-full md:w-auto"
    :class="{
      'flex-wrap':solution_modal
    }"
  >
    <button
      v-for="(valueDriver, index) in filteredDrivers"
      :key="index"
      class="flex transition-all duration-30 items-center ease-in-out hover:opacity-50 "
      :class="{
        'border-[#0F0698] border rounded-full pl-2 pr-3 py-[3px]' :solution_modal
      }"
      @click="openModal(valueDriver.id)"
    >
      <img class="h-8 w-8" :class="{
        'h-[24px] w-[24px] mr-0.5' :solution_modal
      }" 
      :src="valueDriver.image" />
      <span v-if="solution_modal" class="text-sm text-[#0F0698]">{{ valueDriver.name }}</span>
    </button>
  </div>
</template>

<script lang="js" src="./ValueDriverButtons"></script>
