import { getSolutionHotspot } from "@/api/index";
import ImageGallery from "@/components/image-gallery/ImageGallery.vue";
import ArrowButton from "@/components/arrow-button/ArrowButton.vue";
import MoreButton from "@/components/more-button/MoreButton.vue";
import SquareArrowButton from "@/components/square-arrow-button/SquareArrowButton.vue";
import ValueDriverButtons from "@/components/value-driver-buttons/ValueDriverButtons.vue";
import ValueDriverModal from "@/components/value-driver-modal/ValueDriverModal.vue";
import AccordionComponent from "@/components/accordion-component/AccordionComponent.vue";

import {
  ArrowRightIcon,
  ArrowLeftIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "SolutionsHostspotModal",
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
    XMarkIcon,
    ImageGallery,
    ArrowButton,
    MoreButton,
    SquareArrowButton,
    ValueDriverButtons,
    ValueDriverModal,
    AccordionComponent,
  },
  data() {
    return {
      activeFeature: 0,
      hotspot: null,
      isOpen: false,
      isLoading: true,
      driverModalVisible: false,
      valueDriverId: "",
      isSolutionModal: true,
      isNavigating: false,
      activeAccordion: "valueDrivers",
    };
  },
  created() {
    const hotspotSlug = this.$route.params.hotspotSlug;
    getSolutionHotspot(hotspotSlug).then((hotspots) => {
      this.hotspot = hotspots;
      this.isLoading = false;
      this.isOpen = true;
    });
  },
  mounted() {
    this.modalContent = this.$refs.modalContent;
  },
  computed: {
    isFullScreen() {
      return this.$store.state.isFullScreen;
    },
    hasActiveFeature() {
      return this.hotspot.features[this.activeFeature];
    },
    hasImages() {
      return this.hotspot.images?.length > 0;
    },
    hasIcons() {
      return this.hotspot.features[this.activeFeature].icons?.length > 0;
    },
    images() {
      return this.hotspot.images.map((img) => {
        return img.url != "" ? img.url : "/images/layout/placeholder.jpeg";
      });
      
    },
    hotspotTitle() {
      if (this.hotspot.title.length < 27) {
        return this.hotspot.title;
      } else {
        return this.hotspot.title.substring(0, 26) + "...";
      }
    },
    baseURL() {
      return this.$router.resolve({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: "solutions",
        },
      }).href;
    },
    relatedSolutions() {
      return this.hotspot.related_solutions.map((solution) => {
        return {
          title: solution.title,
          link: `${this.baseURL}/${solution.slug}`,
        };
      });
    },
    relatedContent() {
      return this.hotspot.related_content;
    },
    highlightedSolutions() {
      return this.hotspot.highlighted_solutions.map((solution) => {
        return {
          title: solution.title,
          link: `${this.baseURL}/${solution.slug}`,
        };
      });
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    setActiveFeature(index) {
      this.activeFeature = index;
      if (this.$refs.modalContent) {
        this.$refs.modalContent.scrollTop = 0;
      }
    },
    runAfterLeave() {
      if (!this.driverModalVisible) {
        this.$router.push({
          name: "LayerScreen",
          params: {
            category: this.$route.params.category,
            sector: this.$route.params.sector,
            layer: "solutions",
          },
        });
      }
    },
    openDriverModal(valueDriverId) {
      this.isOpen = false;
      this.driverModalVisible = true;
      this.valueDriverId = valueDriverId;
    },
    async getFilteredHotspots() {
      const hotspots = Object.values(this.$store.state.hotspots.hotspots);
      hotspots.sort((a, b) => a.orderindex - b.orderindex);
      return hotspots.filter(
        (hotspot) =>
          hotspot.layer === "solutions" && hotspot.orderindex !== null
      );
    },

    async findHotspotByOrderIndex(orderindex) {
      const filteredHotspots = await this.getFilteredHotspots();
      const hotspotIndex = filteredHotspots.findIndex(
        (hotspot) => hotspot.orderindex === orderindex
      );

      return hotspotIndex !== -1 ? filteredHotspots[hotspotIndex] : null;
    },

    async nextSolution() {
      this.isNavigating = true;
      const currentIndex = this.hotspot.orderindex;
      const filteredHotspots = await this.getFilteredHotspots();
      const currentHotspotIndex = filteredHotspots.findIndex(
        (hotspot) => hotspot.orderindex === currentIndex
      );

      if (currentHotspotIndex === filteredHotspots.length - 1) {
        this.navigateToHotspot(filteredHotspots[0]);
      } else {
        this.navigateToHotspot(filteredHotspots[currentHotspotIndex + 1]);
      }
      this.isNavigating = false;
    },

    async prevSolution() {
      this.isNavigating = true;
      const currentIndex = this.hotspot.orderindex;
      const filteredHotspots = await this.getFilteredHotspots();
      const currentHotspotIndex = filteredHotspots.findIndex(
        (hotspot) => hotspot.orderindex === currentIndex
      );

      if (currentHotspotIndex === 0) {
        this.navigateToHotspot(filteredHotspots[filteredHotspots.length - 1]);
      } else {
        this.navigateToHotspot(filteredHotspots[currentHotspotIndex - 1]);
      }
      this.isNavigating = false;
    },

    navigateToHotspot(hotspot) {
      this.$router.push({
        name: "HotspotModal",
        params: { hotspotSlug: hotspot.slug },
      });
    },

    toggleAccordion(accordionName) {
      this.activeAccordion =
        this.activeAccordion === accordionName ? null : accordionName;
    },
  },
};
