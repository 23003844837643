import { XMarkIcon, ArrowRightIcon } from "@heroicons/vue/24/outline";
import SearchBar from "./components/SearchBar.vue";
import { mapGetters } from "vuex";
import { getApiBase } from "../../api/host";
import store from '../../store/index.js';
import axios from "axios";
import { decodeHtml } from "../../router/utils";


const errorMessages = {
  401: "Unauthorized: Please log in.",
  429: "Too many requests: Please try again later.",
  500: "Internal Server Error: Please try again later.",
  503: "Service Unavailable: Please try again later.",
  504: "Gateway Timeout: Please try again later.",
};

export default {
  name: "SearchModal",
  props: ["isVisible", "hotspots"],
  components: {
    SearchBar,
    XMarkIcon,
    ArrowRightIcon,
  },
  data() {
    return {
      error: null,
      isOpen: true,
      isSearch: true,
      local_hotspots: this.hotspots,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("processes", ["currentCategory", "currentSector"]),
    ...mapGetters("staticContent", ["getTranslations"]), 
  },
  methods: {
    decodeHtml,
    onTermChange(terms) {
      const active_language = store.getters['languages/activeLanguage'];
      active_language ? active_language.two_digit_code : 'en';

      this.error = null;
      if (!terms) {
        this.local_hotspots = [];
        return;
      }

      this.isLoading = true;
      const auth_token = 'VKa3MPCKvDL302BYwy03ebLJcF5FRaMsSVJwHFftTLc5';
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth_token;
      axios
        .get(getApiBase() + "/v1/content/search/" + this.currentSector.id + "?lang=" + active_language.two_digit_code, {
          params: {
            terms: terms,
          },
        })
        .then((response) => {
          if (response.data.length === 0) {
            this.error = 'No results found for "' + terms + '"';
            this.local_hotspots = [];
          } else {
            this.local_hotspots = response.data;
          }
        })
        .catch((error) => {
          this.local_hotspots = [];
          if (error.response) {
            const status = error.response.status;
            this.error = errorMessages[status] || error.response.data.message;
          } else if (error.request) {
            this.error = "No response from the server. Please try again later.";
          } else {
            this.error = error.message;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    close() {
      this.$emit("update:isVisible", false);
    },
    onInput: function (event) {
      this.$emit("termChange", event.target.value);
    },
    openHotspot(hotspot) {
      this.close();
      this.$router.push({
        name: "HotspotModal",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: hotspot.layer,
          hotspotSlug: hotspot.slug,
        },
      });
    },
  },
};
