<template>
  <div class="accordion hover:bg-slate-50 "  :class="{
      'hover:bg-transparent': isExpanded
    }">
    <div class="accordion-header" @click="toggleAccordion">
      <span class="italic capitalize"> {{ getTranslations(title) }}</span>
      <div class="accordion-icon" :class="{ 'expanded': isExpanded }">
        <ChevronUpIcon class="h-[20px] w-[20px] stroke-[3px] transition-all text-[#10069F]" />
      </div>
    </div>
    <div v-if="isExpanded" class="accordion-content">
      <slot></slot>
    </div>
  </div>
</template>


<script lang="js" src="./AccordionComponent.js"></script>
<style scoped lang="scss" src="./Style.scss"></style>
