<template>
  <div
    class="fixed z-[3002] m-4 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute top-0 left-0 h-full w-full bg-[#0A194C] bg-opacity-70"
      @click="toggleLanguageModal"
    ></div>
    <main class="z-10 min-h-[630px] w-11/12 max-w-4xl bg-white py-8 px-7">
      <h2 class="mb-10 text-2xl capitalize text-black">
        {{ getTranslations("choose a language") }}
      </h2>
      <div class="grid w-full grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
        <section
          v-for="language in languages"
          class="rounded-lg bg-[#0F0698] py-2 px-4 text-white"
          :class="
            language.id == activeLanguage.id
              ? 'bg-[#0F0698] text-white'
              : 'cursor-pointer bg-[#F5F5F5] text-[#243F42] hover:opacity-70'
          "
          :key="language.id"
          @click="language.id != activeLanguage.id && updateLanguage(language)"
        >
          <div class="flex items-center gap-2">
            <div class="h-3.5 w-4 rounded-full bg-cover">
              <img
                :src="language.flag"
                :alt="'Flag of ' + language.title"
                class="h-full w-full object-cover"
              />
            </div>

            <h3 class="text-pretty text-lg leading-tight">
              {{ language.title }} ({{ language.two_digit_code }})
            </h3>
          </div>
          <h4 class="text-xs">{{ language.title_native }}</h4>
        </section>
      </div>
    </main>
  </div>
</template>

<script lang="js" src="./LanguageModal.js"></script>
