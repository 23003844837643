<div
  class="fixed top-0 left-0 z-[1000] m-4 flex w-9 flex-col rounded-lg bg-white bg-opacity-70 shadow-lg backdrop-blur-sm backdrop-filter"
>
  <button
    @click="openSearchModal"
    class="flex items-center justify-center p-1"
  >
    <img class="w-full" src="/images/icons/icon-search-blue.png" />
  </button>
  <div class="">
    <button
      @click="onZoomIn"
      class="border-t border-gray-400 border-opacity-20 p-2"
      :class="can_zoom_in ? '' : 'disabled'"
      :disabled="!can_zoom_in"
    >
      <PlusIcon class="w-full" />
    </button>
    <!-- <div class="label py-2 text-center">{{zoom.toFixed(1)}}</div> -->
    <button
      @click="onZoomOut"
      class="border-t border-gray-400 border-opacity-20 p-2"
      :class="can_zoom_out ? '' : 'disabled'"
      :disabled="!can_zoom_out"
    >
      <MinusIcon class="w-full" />
    </button>
  </div>
</div>
