<template>
  <div class="z-50 m-4 flex rounded-xl bg-white bg-opacity-70 text-sm shadow-lg backdrop-blur-sm backdrop-filter">
    <button @click="backToHome" class="border-r py-2 px-4 pr-2 capitalize">
      &lt; {{ getTranslations('back') }}
    </button>
    <button @click="openInfoModal" class="py-2 px-4 pl-4 md:pl-2 flex gap-5 items-center capitalize">
      <span class="md:block hidden">
        {{ getTranslations('help') }}
      </span>
      <img width="16" src="/images/icons/help-icon.svg" />
    </button>
  </div>
</template>

<script lang="js" src="./BackInfoButtons.js"></script>
