<template>
  <button
    @click="openWaterSaving"
    type="button"
    class="water-saving-btn shadow-blue-500/50 text-semibold flex hidden items-center gap-2 rounded-[28px] bg-[#4271C1] px-3 py-1.5 text-center text-sm text-white shadow-lg lg:flex"
  >
    <svg
      width="25"
      height="31"
      viewBox="0 0 25 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-4 h-4"
    >
      <path
        d="M13.3523 27.038C13.6077 27.0094 13.8075 26.9214 13.9517 26.7738C14.0959 26.6263 14.168 26.4484 14.168 26.2401C14.168 25.9971 14.0856 25.809 13.921 25.6758C13.7562 25.5426 13.5458 25.4903 13.2896 25.519C12.1161 25.6048 10.805 25.2388 9.35633 24.4209C7.90768 23.603 7.01162 22.2352 6.66817 20.3176C6.61093 20.0468 6.5157 19.8442 6.3825 19.71C6.2493 19.5757 6.08032 19.5085 5.87556 19.5085C5.651 19.5085 5.45947 19.5927 5.30096 19.7611C5.14243 19.9296 5.09509 20.1811 5.15895 20.5157C5.60149 22.9001 6.7023 24.6064 8.46138 25.6345C10.2205 26.6627 11.8508 27.1305 13.3523 27.038ZM12.9731 31.001C9.54037 31.001 6.67972 29.8198 4.39111 27.4576C2.10254 25.0953 0.958252 22.155 0.958252 18.6367C0.958252 16.1708 1.95284 13.4436 3.942 10.4549C5.93117 7.46616 8.94355 4.18738 12.9791 0.61853C17.0147 4.18738 20.0271 7.46616 22.0162 10.4549C24.0054 13.4436 25 16.1708 25 18.6367C25 22.155 23.8537 25.0953 21.561 27.4576C19.2684 29.8198 16.4057 31.001 12.9731 31.001ZM12.9791 29.2837C15.9557 29.2837 18.4171 28.2748 20.3634 26.257C22.3096 24.2392 23.2827 21.6991 23.2827 18.6367C23.2827 16.5473 22.4169 14.1861 20.6854 11.5529C18.9538 8.91979 16.385 6.04336 12.9791 2.92366C9.57321 6.04336 7.00447 8.91979 5.27289 11.5529C3.54131 14.1861 2.67552 16.5473 2.67552 18.6367C2.67552 21.6991 3.64864 24.2392 5.59487 26.257C7.54111 28.2748 10.0025 29.2837 12.9791 29.2837Z"
        fill="white"
      />
    </svg>
    <span 
    class="text-center"
    v-html="getTranslations('water saving')"
    >
    </span>
    
  </button>
</template>

<script lang="js" src="./WaterSavingButton.js"></script>
<style lang="scss" src="./WaterSavingButton.scss" scoped></style>
