import { mapActions, mapGetters } from "vuex";
export default {
  name: "WaterSavingButton",
  methods: {
    ...mapActions(["setActiveLayer"]),
    ...mapActions("portfolios", ["setActivePortfolio"]),
    ...mapActions("valueDrivers", ["setActiveValueDrivers"]),
    openWaterSaving(){
      this.setLayerAndRedirect({id: "solutions"});
      let water_saving ={id: 8, slug: 'water-saving', name: 'Water Saving'}
      this.setActivePortfolio({ activePortfolio: water_saving });
    },
    setLayerAndRedirect(layer) {
      this.$router.push({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: layer.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
  },
};
