import { useRouter } from "vue-router";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LanguageModal",
  props: ["toggleLanguageModal", "updateLanguages"],
  computed: {
    ...mapGetters("languages", ["languages", "activeLanguage"]),
    ...mapGetters("staticContent", ["getTranslations"]),
  },
  methods: {
    ...mapActions("languages", ["setActiveLanguage"]),
    selectLanguage(language) {
      this.selectedLanguage = language;
    },
    updateLanguage(language) {
      if (language.id === this.activeLanguage.id) {
        return;
      }

      this.setActiveLanguage({ activeLanguage: language });

      const currentRoute = this.$router.currentRoute.value;

      const newRoute = {
        ...currentRoute,
        params: {
          ...currentRoute.params,
          lang: language.two_digit_code,
        },
      };

      this.$router.push(newRoute);

      localStorage.setItem("activeLanguage", JSON.stringify(language));

      window.location.reload();
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
