<template>
  <div
    @click="toggleInfo"
    class=" hidden lg:block fixed bottom-0 left-1/2 z-[1000] m-4 max-w-[400px] -translate-x-1/2 cursor-pointer gap-4 rounded-xl bg-white bg-opacity-70 py-2 px-4 shadow-lg backdrop-blur-sm backdrop-filter"
  >
    <header
      class="flex justify-between text-lg transition-all duration-300"
      :class="{ 'mb-4': expanded }"
    >
      <h1 class="text-xl">{{ title }}</h1>
      <img width="18" src="/images/icons/info-icon.svg" />
    </header>
    <section
      class="flex flex-col gap-4 overflow-hidden text-black transition-all duration-500 ease-in-out"
      :class="expanded ? 'h-[250px]' : 'h-0'"
    >
      <p>
        {{ content }}
      </p>
    </section>
  </div>
</template>
<script lang="js" src="./InfoCard"></script>
