import { getProcessHotspot } from "../../api/index";
import ImageGallery from "@/components/image-gallery/ImageGallery.vue";
import ArrowButton from "@/components/arrow-button/ArrowButton.vue";
import SquareArrowButton from "@/components/square-arrow-button/SquareArrowButton.vue";
import { mapGetters } from "vuex";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "ProcessHostspotModal",
  components: {
    ArrowRightIcon,
    ArrowLeftIcon,
    XMarkIcon,
    ImageGallery,
    ArrowButton,
    SquareArrowButton,
  },
  data() {
    return {
      activeFeature: 0,
      hotspot: null,
      isOpen: false,
      isLoading: true,
      isNavigating: false,
    };
  },
  created() {
    const hotspotSlug = this.$route.params.hotspotSlug;
    getProcessHotspot(hotspotSlug).then((hotspots) => {
      this.hotspot = hotspots;
      this.isLoading = false;
      this.isOpen = true;
    });
  },
  mounted() {
    this.modalContent = this.$refs.modalContent;
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
    hasActiveFeature() {
      return this.hotspot.features[this.activeFeature];
    },
    hasImages() {
      return this.hotspot.images?.length > 0 || this.hotspot.images?.length > 0;
    },
    hasIcons() {
      return this.hotspot.features[this.activeFeature].icons?.length > 0;
    },
    images() {
      return this.hotspot.images.map((img) => {
        return img.url != "" ? img.url : "/images/layout/placeholder.jpeg";
      });
    },
    hotspotTitle() {
      if (this.hotspot.title.length < 27) {
        return this.hotspot.title;
      } else {
        return this.hotspot.title.substring(0, 26) + "...";
      }
    },
    baseURL() {
      return this.$router.resolve({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: "solutions",
        },
      }).href;
    },
    relatedSolutions() {
      return this.hotspot.related_solutions.map((solution) => {
        return {
          title: solution.title,
          link: `${this.baseURL}/${solution.slug}`,
        };
      });
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    setActiveFeature(index) {
      this.activeFeature = index;
      if (this.$refs.modalContent) {
        this.$refs.modalContent.scrollTop = 0;
      }
    },

    runAfterLeave() {
      const layer = this.$route.path.split("/")[3];
      const currentLayer =
        layer === "process" 
          ? "process"
          : "process-equipment";
      this.$router.push({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: currentLayer,
        },
      });
    },

    async getFilteredHotspots() {
      const hotspots = Object.values(this.$store.state.hotspots.hotspots);
      hotspots.sort((a, b) => a.orderindex - b.orderindex);

      const layer = this.$route.params.layer;

      return hotspots.filter(
        (hotspot) =>
          hotspot.layer === layer && hotspot.orderindex !== null
      );
    },

    async findHotspotByOrderIndex(orderindex) {
      const filteredHotspots = await this.getFilteredHotspots();
      const hotspotIndex = filteredHotspots.findIndex(
        (hotspot) => hotspot.orderindex === orderindex
      );
 
      return hotspotIndex !== -1 ? filteredHotspots[hotspotIndex] : null;
    },

    async nextProcess() {
      this.isNavigating = true;
      const currentIndex = this.hotspot.orderindex;
      const filteredHotspots = await this.getFilteredHotspots();
      const currentHotspotIndex = filteredHotspots.findIndex(hotspot => hotspot.orderindex === currentIndex);

      // console.log(currentHotspotIndex);
      console.log(filteredHotspots);
      // console.log(filteredHotspots[currentHotspotIndex + 1]);
    
      if (currentHotspotIndex === filteredHotspots.length - 1) {
        this.navigateToHotspot(filteredHotspots[0]);
      } else {
        this.navigateToHotspot(filteredHotspots[currentHotspotIndex + 1]);
      }
      this.isNavigating = false;
    },
    
    async prevProcess() {
      this.isNavigating = true;
      const currentIndex = this.hotspot.orderindex;
      const filteredHotspots = await this.getFilteredHotspots();
      const currentHotspotIndex = filteredHotspots.findIndex(hotspot => hotspot.orderindex === currentIndex);
    
      if (currentHotspotIndex === 0) {
        this.navigateToHotspot(filteredHotspots[filteredHotspots.length - 1]);
      } else {
        this.navigateToHotspot(filteredHotspots[currentHotspotIndex - 1]);
      }
      this.isNavigating = false;
    },

    navigateToHotspot(hotspot) {
      console.log(hotspot);
      this.$router.push({
        name: "HotspotModal",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: hotspot.layer,
          hotspotSlug: hotspot.slug,
        },
      });
    },
  },
};
