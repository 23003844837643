import { mapGetters } from "vuex";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import SquareArrowButton from "@/components/square-arrow-button/SquareArrowButton.vue";

export default {
  name: "ValueDriverModal",
  components: {
    XMarkIcon,
    SquareArrowButton,
  },
  props: ["isVisible", "valueDriverId", "runAfterLeave"],
  computed: {
    ...mapGetters("valueDrivers", ["valueDrivers"]),
    ...mapGetters("staticContent", ["getTranslations"]),
    currentValueDriver() {
      return this.valueDrivers.find(
        (valueDriver) => valueDriver.id === this.valueDriverId
      );
    },
    title() {
      return this.currentValueDriver?.name;
    },
    description() {
      return this.currentValueDriver?.description;
    },
    iconSrc() {
      return this.currentValueDriver?.image;
    },
    baseURL() {
      return this.$router.resolve({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: "solutions",
        },
      }).href;
    },
    related_solutions() {
      return this.currentValueDriver?.related_solutions.map((solution) => {
        return {
          title: solution.title,
          link: `${this.baseURL}/${solution.slug}`,
        };
      });
    },
  },
  methods: {
    close() {
      this.$emit("update:isVisible", false);
    },
  },
};
