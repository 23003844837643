<template>
  <teleport to="#app">
    <BaseDialog :isOpen="isVisible" @close="close" :isSearch="isSearch">
      <template #default>
        <div
          class="flex w-[75vw] flex-col md:w-[50vw] h-auto overflow-hidden"
          :class="{
            'md:h-[40vh]': hotspots.length > 0 || error,
            'h-auto': hotspots.length === 0 && !error,
          }"
        >
          <div
            v-if="isLoading"
            class="absolute top-1 left-0 flex h-0 w-full items-center justify-center"
          >
            <LoaderSearch :isLoading="isLoading" />
          </div>

          <SearchBar @termChange="onTermChange"></SearchBar>
  
            <ul
              class="m-0 list-none overflow-scroll text-[#0f0f98] overflow-x-hidden overflow-y-scroll "
              :class="{
                'border-t border-gray-300 px-2 pt-1 pb-4 ':
                  hotspots.length > 0 || error,
                'p-0 border-0': hotspots.length === 0 && !error,
              }"
            >
              <li
                v-for="hotspot in local_hotspots"
                v-bind:hotspot="hotspot"
                :key="hotspot.id"
                @click="openHotspot(hotspot)"
                class="group flex cursor-pointer items-center justify-between gap-4 px-2  py-3 text-[#0f0f98] transition-all duration-300 last:border-b-0 rounded hover:bg-gray-200"
              >
                <p class="">
                  {{ decodeHtml(hotspot.title) }}
                  <span class="text-[#70717d]"> ({{ this.getTranslations(hotspot.layer.replace(/-/g, " ").replace(/&nbsp;/g, " ")) }}) </span>
                </p>
                <div
                  class="flex h-[24px] w-[24px] items-center justify-center rounded-full border border-[#e3551f] p-1 transition-all duration-300 group-hover:bg-[#e3551f] group-hover:bg-opacity-100"
                >
                  <ArrowRightIcon
                    class="h-[12px] w-[12px] stroke-[5] text-[#e3551f] transition-all duration-300 group-hover:text-white"
                  />
                </div>
              </li>
            </ul>
      
          <div class="px-4">
            <div v-if="error" class="letter-spacing-1 text-slate-700 text-lg font-bold ">
              {{ error }}
            </div>
            <p class="capitalize" v-if="error === 'no_response'">
              {{ getTranslations('no response from the server. please try again later') }}
            </p>
          </div>
        </div>
      </template>
    </BaseDialog>
  </teleport>
</template>

<script src="./SearchModal.js" lang="js"></script>
